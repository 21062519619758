<template>
    <div class="list-item p-2 bg-white w-100">
        <span>{{ item.name[$i18n.locale] }} ({{ item.name["en"] }})</span>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                required: true
            },
            searchText: {
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-item {
        &:hover {
            background-color: rgba(238, 238, 238, 1);
        }
    }
</style>

<template>
    <section class="checkout-delivery">
        <div class="mb-1" role="group">
            <label>
                <strong>
                    {{ $t("message.checkout.email") }}
                </strong>
            </label>
            <b-form-input type="email"
                          v-bind:disabled="userStatus === 'loggedIn'"
                          v-model.trim="email">
            </b-form-input>
            <b-form-invalid-feedback>
                Enter at least 3 letters
            </b-form-invalid-feedback>
            <b-form-text>
                {{ $t("message.checkout.contact-email-notice") }}
            </b-form-text>
        </div>

        <div class="mb-3" role="group">
            <label>
                <strong>{{ $t("message.checkout.phone") }}</strong>
            </label>
            <b-form-input type="tel"
                          v-bind:disabled="userStatus === 'loggedIn' && !!user.phone"
                          v-model.trim="phone">
            </b-form-input>
            <b-form-invalid-feedback>
                Enter at least 3 letters
            </b-form-invalid-feedback>
            <b-form-text>
                {{ $t("message.checkout.contact-phone-notice") }}
            </b-form-text>
        </div>

        <div v-if="type === 'products'">
            <hr class="mb-4">

            <h4 class="mb-3">
                {{ $t("message.checkout.delivery-address") }}
            </h4>

            <div class="container">
                <div class="row cards">
                    <div class="col-lg-6 mb-4"
                         v-for="address in addresses"
                         v-bind:key="address._id">
                        <address-card v-bind:address="address"
                                      v-on:click.native="selectAddress(address)">
                        </address-card>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <div class="add-address card h-100"
                             v-on:click="showAddAddressModal">
                            <div class="card-body h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                                <h2>
                                    <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                                </h2>
                                <h5>
                                    {{ $t("message.checkout.add-address") }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal ref="modalAddAddress"
                 size="lg"
                 hide-footer
                 title="Add an address"
                 v-if="type === 'products'"
                 v-on:cancel="resetNewAddress">
            <template>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName">
                            {{ $t("message.checkout.first-name") }}
                        </label>
                        <input type="text" class="form-control" id="firstName" required
                               v-model="newAddress.firstName">
                        <div class="invalid-feedback">
                            Valid first name is required.
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName">
                            {{ $t("message.checkout.last-name") }}
                        </label>
                        <input type="text" class="form-control" id="lastName" required
                               v-model="newAddress.lastName">
                        <div class="invalid-feedback">
                            Valid last name is required.
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label>
                        {{ $t("message.checkout.address.title") }}
                    </label>
                    <input type="text" class="form-control" placeholder="1234 Main St" required
                           v-model="newAddress.addressLine1">
                    <div class="invalid-feedback">
                        Please enter your shipping address.
                    </div>
                </div>

                <div class="mb-3">
                    <label>{{ $t("message.checkout.address.title") }} 2 <span class="text-muted">({{ $t("message.checkout.optional") }})</span></label>
                    <input type="text" class="form-control" placeholder="Apartment or suite"
                           v-model="newAddress.addressLine2">
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label>{{ $t("message.checkout.country") }}</label>
                        <v-autocomplete v-bind:min-len="2"
                                        v-bind:wait="100"
                                        v-bind:items="filteredCountries"
                                        v-model="newAddress.country"
                                        v-bind:input-attrs="{ 'class': ['form-control'] }"
                                        v-bind:component-item="countryListItemTemplate"
                                        v-bind:get-label="getLabel"
                                        v-on:update-items="getFilterCountries">
                        </v-autocomplete>

                        <div class="invalid-feedback">
                            Please select a valid country.
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label>{{ $t("message.checkout.postcode") }} <span class="text-muted">({{ $t("message.checkout.optional") }})</span></label>
                        <b-form-input class="form-control" type="text"
                                      v-model="newAddress.postcode">
                        </b-form-input>
                    </div>
                </div>

                <div class="text-center">
                    <button class="btn btn-dark verify-otp px-4"
                            v-bind:class="{ 'btn-loading': isLoading }"
                            v-bind:disabled="!allowAddAddress"
                            v-on:click="addAddress">
                        {{ $t("message.checkout.add") }}
                    </button>
                </div>
            </template>
        </b-modal>

        <h4 class="mb-3"
            v-if="type === 'products'">
            {{ $t("message.checkout.delivery-method") }}
        </h4>

        <div class="mb-3"
             v-if="type === 'products'">
            <b-form-radio-group stacked
                                v-model="deliveryType">
                <b-form-radio value="standard">
                    {{ $t("message.checkout.delivery-standard") }}
                </b-form-radio>
            </b-form-radio-group>
        </div>

        <button class="btn btn-dark btn-block"
                v-bind:disabled="type === 'products' ? !allowNextProducts : !allowNextTreatments"
                v-on:click="next">
            {{ $t("message.checkout.next") }}
        </button>
    </section>
</template>

<script>
import {
    BFormText,
    BFormInvalidFeedback,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BModal,
} from "bootstrap-vue";

import ItemTemplate from "./CountryListItemTemplate.vue";
import AddressCard from "./Delivery/AddressCard";
import countries from "../../assets/country.json";

export default {
    name: "Delivery",
    components: {
        AddressCard,
        BFormText,
        BFormInvalidFeedback,
        BFormInput,
        BFormRadio,
        BFormRadioGroup,
        BModal,
    },
    props: {
        "type": {
            type: String,
        },
    },
    data () {
        return {
            isLoading: false,
            countryListItemTemplate: ItemTemplate,
            filteredCountries: countries,

            email: "",
            phone: "",
            selectedAddress: null,
            deliveryType: "standard",

            addresses: [],

            newAddress: {
                firstName: "",
                lastName: "",
                addressLine1: "",
                addressLine2: "",
                country: null,
                postcode: "",
            },
        }
    },
    computed: {
        userStatus () {
            return this.$store.state.user.status;
        },

        user () {
            return this.$store.state.user.user;
        },

        allowAddAddress () {
            return this.newAddress.firstName && this.newAddress.lastName && this.newAddress.addressLine1 && this.newAddress.country;
        },

        allowNextProducts () {
            return this.selectedAddress && this.phone && this.email && this.deliveryType;
        },

        allowNextTreatments () {
            return this.phone && this.email;
        },

        order () {
            let orderMap = {
                "products": "shop",
                "treatments": "salon"
            };

            if (this.$route.params.orderId) {
                let target = this.$store.state.mall.orders[orderMap[this.$route.params.type]].find(order => order._id === this.$route.params.orderId);
                if (target) {
                    console.log("order-completion", target);
                    return target;
                } else {
                    return null
                }
            }
        },
    },
    methods: {
        getLabel (item) {
            if (item) {
                return item.name[this.$i18n.locale];
            }
            return "";
        },

        getFilterCountries (text) {
            this.filteredCountries = countries.filter(
                (country) => Object.keys(country.name).reduce(
                    (acc, lang) => acc += ` ${ country.name[lang] }`, ""
                ).trim().toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
            );
        },

        selectAddress (address) {
            this.addresses.forEach(
                address => address.selected = false
            );
            address.selected = true;
            this.selectedAddress = address;
            this.$store.dispatch("wallet/setLocalSessionDeliveryOptions", {
                address: this.selectedAddress,
            });
        },

        showAddAddressModal () {
            this.$refs.modalAddAddress.show();
        },

        async addAddress () {
            this.isLoading = true;
            try {
                await this.$store.dispatch("wallet/addAddress", this.newAddress);
            } catch (e) {
                console.error(e);
                this.isLoading = false;
            }
            this.isLoading = false;
            this.$refs.modalAddAddress.hide();
            this.resetNewAddress();
        },

        resetNewAddress () {
            this.newAddress = {
                firstName: "",
                lastName: "",
                addressLine1: "",
                addressLine2: "",
                country: "",
                postcode: "",
            };
        },

        next () {
            this.$store.dispatch("wallet/setLocalSessionDeliveryOptions", {
                email: this.email,
                phone: this.phone,
                address: this.selectedAddress,
                deliveryType: this.deliveryType,
            });
            this.email = "";
            this.phone = "";
            this.selectedAddress = null;
            this.deliveryType = "";
            this.$router.push({
                name: "checkout-order-details",
            });
        }
    },
    mounted () {
        this.addresses = this.$store.state.wallet.addresses;
        this.email = this.$store.state.user.user.email;
        this.phone = this.$store.state.user.user.phone;
        this.selectedAddress = this.addresses.find(address => address.selected);

        if (this.cart) {
            if (this.cart.length === 0) {
                this.$router.push({
                    name: "basket",
                });
            }
        }
    },
    watch: {
        "$store.state.wallet.addresses" (newVal, oldVal) {
            this.addresses = newVal;
        },

        "$store.state.user.user.email" (newVal, oldVal) {
            this.email = newVal;
        },

        "$store.state.user.user.phone" (newVal, oldVal) {
            this.phone = newVal;
        },

        "cart" (newVal) {
            if (newVal) {
                if (newVal.length === 0) {
                    this.$router.push({
                        name: "basket",
                    });
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.checkout-delivery {
    color: rgba(113, 113, 113, 1);

    .add-address {
        cursor: pointer;
    }
}
</style>

<template>
    <article class="address card overflow-hidden shadow-sm h-100"
             v-bind:class="{ 'selected': address.selected }">
        <div class="card-header text-center p-2">
            {{ address.selected ? $t("message.checkout.address.selected") : $t("message.checkout.address.select") }}
        </div>
        <div class="card-body position-relative">
            <h4>
                {{ address.firstName }} {{ address.lastName }}
            </h4>
            <div>
                {{ address.addressLine1 }}
            </div>
            <div>
                {{ address.addressLine2 }}
            </div>
            <div>
                {{ address.postcode }}
            </div>
            <div>
                {{ address.country.name[$i18n.locale] }}
            </div>
        </div>
        <div class="card-footer p-0">
            <div class="btn-group d-flex">
                <button class="flex-1 btn btn-outline-danger border-0 rounded-0 p-2"
                        v-on:click="deleteAddress(address._id)">
                    {{ $t("message.checkout.address.delete") }}
                </button>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "AddressCard",
    props: {
        address: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            isLoading: false,
        }
    },
    methods: {
        deleteAddress (id) {
            this.$store.dispatch("wallet/deleteAddress", id);
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    cursor: pointer;
    user-select: none;

    .card-header,
    .card-footer {
        border-radius: 0;
        background-color: rgba(255, 255, 255, 1);
    }

    &.add-card {
        color: rgba(113, 113, 113, 1);
        background: rgba(240, 240, 240, 1);

        &:hover,
        &:active {
            color: rgba(0, 0, 0, 0.38);
            font-weight: bold;
        }
    }

    &.selected {
        border-color: rgba(40, 167, 69, 1);

        .card-header {
            background-color: rgba(40, 167, 69, 1);
            color: rgba(255, 255, 255, 1);
        }
    }
}
</style>
